<template>
  <div class="about" v-if="render">
    <!-- intro -->
    <v-container fluid class="py-0">
      <v-row class="Contact__first-row in-about">
        <v-col cols="12" sm="6" class="Contact__column-height">
          <v-row no-gutters class="fill-height" justify="center" align="center">
            <v-col cols="auto">
              <v-carousel
                :hide-delimiters="true"
                :show-arrows="false"
                cycle
                v-bind:height="heightTextCarousel"
              >
                <v-carousel-item
                  v-for="(texto, textoID) in aboutSingleton.about_title"
                  :key="'id' + textoID"
                  transition="fade-transition"
                  reverse-transition="fade-transition"
                >
                  <div class="max-width-about-title">
                    <div class="icon-plus">
                      <img
                        :src="
                          'https://www.cymcms.actstudio.xyz/' +
                          texto.value.Icono.path
                        "
                      />
                    </div>
                    <span
                      class="montserrat-bold titles-big-x color-cream line-height-some-titles"
                    >
                      {{ texto.value.title }}
                    </span>
                  </div>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <template v-if="aboutSingleton.about_cover_intro != null">
            <template v-if="aboutSingleton.about_cover_intro.video == true">
              <video
                width="100%"
                class="Contact__img"
                :autoplay="true"
                :muted="true"
                :playsinline="true"
                :controls="false"
                :loop="true"
                style="object-fit: cover"
              >
                <source
                  :src="
                    'https://www.cymcms.actstudio.xyz/storage/uploads' +
                    aboutSingleton.about_cover_intro.path
                  "
                  type="video/mp4"
                />
              </video>
            </template>

            <template
              v-else-if="aboutSingleton.about_cover_intro.image == true"
            >
              <v-img
                :src="
                  'https://www.cymcms.actstudio.xyz/storage/uploads' +
                  aboutSingleton.about_cover_intro.path
                "
                class="Contact__img in-about"
              ></v-img>
            </template>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <!-- /intro -->

    <v-container class="py-0 py-sm-12">
      <!-- content 1 -->
      <v-row align="center">
        <v-col cols="12" sm="6" xl="5" class="py-12 py-sm-3">
          <v-row no-gutters justify="end" justify-sm="start">
            <v-col cols="auto">
              <div class="max-width-div-about">
                <span
                  class="d-block titles-big-about-us line-height-some-titles"
                  >{{ aboutSingleton.card_texts_1.title }}</span
                >

                <span
                  class="d-block continuos-texts-small-54 words mt-3 mt-xl-12"
                  >{{ aboutSingleton.card_texts_1.description }}</span
                >
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" xl="7" class="pa-0 pa-sm-3">
          <template v-if="aboutSingleton.cover_1 != null">
            <template v-if="aboutSingleton.cover_1.video == true">
              <video
                width="100%"
                class="img-cards-about"
                style="object-fit: cover"
                :autoplay="true"
                :muted="true"
                :playsinline="true"
                :controls="false"
                :loop="true"
              >
                <source
                  :src="
                    'https://www.cymcms.actstudio.xyz/storage/uploads' +
                    aboutSingleton.cover_1.path
                  "
                  type="video/mp4"
                />
              </video>
            </template>
            <template v-else-if="aboutSingleton.cover_1.image == true">
              <v-img
                :src="
                  'https://www.cymcms.actstudio.xyz/storage/uploads' +
                  aboutSingleton.cover_1.path
                "
                class="img-cards-about"
              ></v-img>
            </template>
          </template>
        </v-col>
      </v-row>
      <!-- /content 1 -->

      <!-- content 2 -->
      <v-row align="center" style="padding-top: 48px">
        <v-col
          cols="12"
          sm="6"
          xl="7"
          class="pa-0 pa-sm-3"
          order="1"
          order-sm="0"
        >
          <template v-if="aboutSingleton.cover_2 != null">
            <template v-if="aboutSingleton.cover_2.video == true">
              <video
                width="100%"
                class="img-cards-about"
                style="object-fit: cover"
                :autoplay="true"
                :muted="true"
                :playsinline="true"
                :controls="false"
                :loop="true"
              >
                <source
                  :src="
                    'https://www.cymcms.actstudio.xyz/storage/uploads' +
                    aboutSingleton.cover_2.path
                  "
                  type="video/mp4"
                />
              </video>
            </template>

            <template v-else-if="aboutSingleton.cover_2.image == true">
              <v-img
                :src="
                  'https://www.cymcms.actstudio.xyz/storage/uploads' +
                  aboutSingleton.cover_2.path
                "
                class="img-cards-about"
              ></v-img>
            </template>
          </template>
        </v-col>

        <v-col cols="12" sm="6" xl="5" class="py-12 py-sm-3">
          <v-row no-gutters justify="end">
            <v-col cols="auto">
              <div class="max-width-div-about">
                <span class="d-block titles-big line-height-some-titles">{{
                  aboutSingleton.card_texts_2.title
                }}</span>

                <span class="d-block words mt-12 continuos-texts-small-54">{{
                  aboutSingleton.card_texts_2.description
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /content 2 -->
    </v-container>

    <v-container class="py-0 py-sm-12" fluid>
      <!-- distribution -->

      <v-row justify="center" class="row__red--block">
        <v-col cols="12" sm="12" md="10">
          <v-row no-gutters class="pa-0">
            <v-col cols="12" sm="6" class="border-right-in-about">
              <v-row
                no-gutters
                justify="center"
                class="row__red--block--height-counters"
              >
                <v-col cols="auto" class="text-center px-sm-8 px-md-16">
                  <div class="position-relative">
                    <div class="row__red--block--height-counters--icon-plus">
                      <span
                        class="counters-about montserrat-bold row__red--block--white-words"
                        >{{ commasNumber(value) }}</span
                      >
                      <img
                        :src="
                          'https://www.cymcms.actstudio.xyz/' +
                          aboutSingleton.iconPlusNumbers.path
                        "
                        class="d-inline-block"
                      />
                    </div>
                    <span class="d-block only-22 row__red--block--white-words">
                      {{ aboutSingleton.productsDescription }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row
                no-gutters
                justify="center"
                class="row__red--block--height-counters"
              >
                <v-col cols="auto" class="text-center px-sm-8 px-md-16">
                  <div class="position-relative">
                    <div class="row__red--block--height-counters--icon-plus">
                      <span
                        class="counters-about montserrat-bold row__red--block--white-words pl-7"
                        >{{ commasNumber(valueBarstools) }}</span
                      >
                      <img
                        :src="
                          'https://www.cymcms.actstudio.xyz/' +
                          aboutSingleton.iconPlusNumbers.path
                        "
                      />
                    </div>

                    <span class="d-block only-22 row__red--block--white-words">
                      {{ aboutSingleton.bartoolsDescription }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" class="row__distribution">
        <v-col cols="12" sm="12" md="10">
          <!-- title -->
          <v-row no-gutters class="my-12">
            <v-col
              cols="12"
              class="text-center"
              v-for="(item, index) in aboutSingleton.iconsBlock"
              :key="'item-' + index"
              v-show="index == 0"
            >
              <v-img
                style="object-fit: cover"
                class="icon-1"
                :src="
                  'https://www.cymcms.actstudio.xyz/' + item.value.Icono.path
                "
              ></v-img>
              <span class="titles-big">
                {{ item.value.title }}
              </span>
            </v-col>
          </v-row>
          <!-- /title -->

          <!-- content -->
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="4"
              v-for="(item, i) in aboutSingleton.iconsBlock"
              v-bind:key="i"
              v-show="i != 0"
              v-bind:class="{
                'border-right-in-about-distribution': i % 3 == 1,
                'border-right-each-1': i % 2 == 0,
              }"
              class="padding-inside-only-sm"
            >
              <v-row no-gutters justify="center">
                <v-col cols="auto">
                  <v-card color="#ECE8DD" tile elevation="0">
                    <v-row no-gutters class="text-center">
                      <v-col cols="12" class="column-icon">
                        <v-img
                          style="object-fit: cover"
                          :src="
                            'https://cymcms.actstudio.xyz/' +
                            item.value.Icono.path
                          "
                          class="icon-1"
                        ></v-img>
                      </v-col>

                      <v-col cols="12">
                        <v-card-text class="pa-0 max-width-distribution">
                          <span class="d-block continuos-texts-2">{{
                            item.value.title
                          }}</span>
                          <span
                            class="continuos-texts-small-54"
                            v-show="i != 1"
                            >{{ item.value.descrition }}</span
                          >
                          <a
                            target="_blank"
                            href="https://goo.gl/maps/7RFeh77d8XNhWMs76"
                            v-show="i == 1"
                            class="not-text-decoration"
                          >
                            <span class="continuos-texts-small-54">{{
                              item.value.descrition
                            }}</span></a
                          >
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- /content -->
        </v-col>
      </v-row>
      <!-- distribution -->
    </v-container>

    <v-container class="py-0">
      <!-- **********inicia stores********** -->
      <v-row class="mt-12 Stores d-none" justify="center">
        <v-col cols="12">
          <div class="text-center stores-title">
            <span class="titles-big">{{ aboutSingleton.stores_title }}</span>
          </div>
        </v-col>

        <!-- <Stores></Stores> -->
      </v-row>
      <!-- **********/termina stores********** -->

      <!-- **********inicia div - we like...********** -->
      <v-row class="position-relative">
        <!-- col sm to down -->
        <v-col
          cols="12"
          class="d-none d-sm-block"
          sm="4"
          order="1"
          order-sm="0"
        >
          <div class="div-we-like">
            <v-row
              no-gutters
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-col cols="auto">
                <div class="div-we-like--max-width">
                  <span
                    class="div-we-like__span bree-serif line-height-some-titles"
                    >{{ aboutSingleton.text_3 }}</span
                  >
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <!-- /col sm to down -->

        <v-col cols="12" sm="8" class="pa-0 pa-sm-3">
          <template v-if="aboutSingleton.cover_3 != null">
            <template v-if="aboutSingleton.cover_3.video == true">
              <video
                width="100%"
                style="object-fit: cover"
                :autoplay="true"
                :muted="true"
                :playsinline="true"
                :controls="false"
                :loop="true"
              >
                <source
                  :src="
                    'https://www.cymcms.actstudio.xyz/storage/uploads' +
                    aboutSingleton.cover_3.path
                  "
                  type="video/mp4"
                />
              </video>
            </template>

            <template v-else-if="aboutSingleton.cover_3.image == true">
              <v-img
                :src="
                  'https://www.cymcms.actstudio.xyz/storage/uploads' +
                  aboutSingleton.cover_3.path
                "
                class=""
              ></v-img>
            </template>
          </template>
        </v-col>
      </v-row>
      <!-- row only xs -->
      <v-row justify="center" class="d-sm-none row-only-xs">
        <v-col cols="auto">
          <div class="div-we-like">
            <v-row
              no-gutters
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-col cols="auto">
                <div class="div-we-like--max-width">
                  <span class="div-we-like__span bree-serif">{{
                    aboutSingleton.text_3
                  }}</span>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <!-- /row only xs -->

      <!-- first carousel -->
      <v-row class="mt-sm-12 carousel-about">
        <v-col cols="12" sm="6" class="pa-0 position-relative">
          <div class="div-dots">
            <div
              v-for="(dot, dt) in aboutSingleton.carousel_1"
              v-bind:key="dt"
              v-bind:class="{ 'active-dot': dt == carousel1 }"
              @click="carousel1 = dt"
              class="div-dots__dot"
            ></div>
          </div>

          <v-carousel
            v-model="carousel1"
            v-bind:show-arrows="false"
            v-bind:height="heightCarousel"
            hide-delimiters
            cycle
            interval="5000"
          >
            <v-carousel-item
              v-for="(image, img) in aboutSingleton.carousel_1"
              v-bind:key="img"
              v-bind:src="
                'https://www.cymcms.actstudio.xyz/' + image.value.path
              "
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row no-gutters justify="center" class="fill-height" align="center">
            <v-col cols="auto">
              <div class="carousel-about--max-width">
                <span
                  class="d-block titles-medium-32 carousel-about--line-height"
                  >{{ aboutSingleton.card_texts_3.title }}</span
                >
                <span
                  class="d-block words continuos-texts-small-54 mt-3 mt-sm-1 mt-md-8"
                  >{{ aboutSingleton.card_texts_3.description }}</span
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /first carousel -->

      <!-- bubble img -->
      <v-row class="mt-sm-12 carousel-about row-reverse">
        <v-col cols="12" sm="6" class="position-relative">
          <!-- v-bind:height="heightCarousel" -->

          <template v-if="aboutSingleton.bubble_images != null">
            <template v-if="aboutSingleton.bubble_images.video == true">
              <video
                width="100%"
                :height="heightCarousel"
                style="object-fit: cover"
                :autoplay="true"
                :muted="true"
                :playsinline="true"
                :controls="false"
                :loop="true"
              >
                <source
                  :src="
                    'https://www.cymcms.actstudio.xyz/storage/uploads' +
                    aboutSingleton.bubble_images.path
                  "
                  type="video/mp4"
                />
              </video>
            </template>

            <template v-else-if="aboutSingleton.bubble_images.image == true">
              <v-img
                :src="
                  'https://www.cymcms.actstudio.xyz/storage/uploads' +
                  aboutSingleton.bubble_images.path
                "
                height="100%"
              ></v-img>
            </template>
          </template>
        </v-col>

        <v-col cols="12" sm="6">
          <v-row no-gutters justify="center" class="fill-height" align="center">
            <v-col cols="auto">
              <div class="carousel-about--max-width">
                <span
                  class="d-block titles-medium-32 carousel-about--line-height"
                  >{{ aboutSingleton.card_texts_4.title }}</span
                >
                <span
                  class="d-block words continuos-texts-small-54 mt-3 mt-sm-1 mt-md-8"
                  >{{ aboutSingleton.card_texts_4.description }}</span
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /bubble img -->

      <!-- second carousel -->
      <v-row class="mt-sm-12 carousel-about">
        <v-col cols="12" sm="6" class="pa-0 position-relative">
          <div class="div-dots">
            <div
              v-for="(dot, dt2) in aboutSingleton.carousel_2"
              v-bind:key="dt2"
              class="div-dots__dot"
              v-bind:class="{ 'active-dot': dt2 == carousel2 }"
              @click="carousel2 = dt2"
            ></div>
          </div>

          <v-carousel
            v-model="carousel2"
            v-bind:show-arrows="false"
            v-bind:height="heightCarousel"
            hide-delimiters
            cycle
            interval="5000"
          >
            <v-carousel-item
              v-for="(image, im2) in aboutSingleton.carousel_2"
              v-bind:key="im2"
              v-bind:src="
                'https://www.cymcms.actstudio.xyz/' + image.value.path
              "
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
            </v-carousel-item>
          </v-carousel>
        </v-col>

        <v-col cols="12" sm="6">
          <v-row no-gutters justify="center" class="fill-height" align="center">
            <v-col cols="auto">
              <div class="carousel-about--max-width">
                <span
                  class="d-block titles-medium-32 carousel-about--line-height mt-7"
                  >{{ aboutSingleton.card_texts_5.title }}</span
                >
                <span
                  class="d-block words continuos-texts-small-54 mt-3 mt-sm-1 mt-md-8"
                  >{{ aboutSingleton.card_texts_5.description }}</span
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /second carousel -->
    </v-container>

    <ContactUs></ContactUs>
  </div>
</template>

<script>
import Stores from "../components/Globals/Stores";
import icon1 from "../assets/icons/Fábrica.svg";
import icon2 from "../assets/icons/Sketch.svg";
import icon3 from "../assets/icons/Garantía.svg";
import icon4 from "../assets/icons/Silla.svg";
import icon5 from "../assets/icons/Camión.svg";
import icon6 from "../assets/icons/Tienda.svg";
import ContactUs from "../components/Globals/ContactUs";

export default {
  components: {
    Stores,
    ContactUs,
  },
  data() {
    return {
      render: false,
      interval: {},
      intervalBarstools: {},
      value: 0,
      valueBarstools: 0,
      valueDeliveries: 0,
      aboutSingleton: {},
      distributionLogistics: [
        {
          title: "Location",
          description:
            "120 miles south Laredo, Tx.\nMexico’s Industrial City by Excellence",
          icon: icon1,
        },
        {
          title: "Design Process",
          description:
            "We like to work hand in hand with our \nclients to achieve their dream design.",
          icon: icon2,
        },
        {
          title: "Quality Guarantee",
          description:
            "The best materials and best processes \nguarantee the best product outcome. \nWe are certified by _______ since 1990. ",
          icon: icon3,
        },
        {
          title: "Product",
          description:
            "Final product won’t be final until \nwe have your complete approval.",
          icon: icon4,
        },
        {
          title: "Distribution",
          description:
            "We can provide import services \nthat best match your needs.",
          icon: icon5,
        },
        {
          title: "Where to purchase",
          description:
            "We can be found in America’s \nmost important retailers.",
          icon: icon6,
        },
      ],
      carousel1: 0,
      carousel2: 0,
      heightCarousel: 500,
      heightTextCarousel: 250,
    };
  },
  beforeCreate() {
    //fetch para el singleton de contacto
    fetch(
      "https://www.cymcms.actstudio.xyz/api/singletons/get/about?token=aa5f1f62f706c89e9dae0ea5e2400a"
    )
      .then((data) => data.json())
      .then((data) => (this.aboutSingleton = data))
      .then((data) => (this.render = true))
      .catch((error) => console.log(error.message));
  },
  mounted() {
    //evento onResize
    window.addEventListener("resize", this.onResize, { passive: true });
    window.addEventListener("scroll", this.onScroll, { passive: true });

    //emite el valor para cambiar el valor del logo
    this.$emit("update", 1);
  },
  beforeDestroy() {
    if (typeof window !== undefined) {
      window.addEventListener("resize", this.onResize, { passive: true });
    }
    clearInterval(this.interval);
  },
  methods: {
    commasNumber:function(value){
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    
    onResize: function (e) {
      if (window.innerWidth <= 599) {
        this.heightCarousel = 272;
        this.heightTextCarousel = 250;
      } else if (window.innerWidth >= 600 && window.innerWidth <= 959) {
        this.heightCarousel = 233;
      } else if (window.innerWidth >= 960 && window.innerWidth <= 1263)
        this.heightCarousel = 303;
      else if (window.innerWidth >= 1264 && window.innerWidth <= 1903) {
        this.heightCarousel = 377;
        this.heightTextCarousel = 100;
      } else if (window.innerWidth >= 1904) {
        this.heightCarousel = 559;
        this.heightTextCarousel = 280;
      }
    },
    firstLetterUppercase: function (text) {
      // return text.charAt(0).toUpperCase() + text.slice(1);
      return (text || "").charAt(0).toUpperCase() + (text || "").slice(1);
    },
    onScroll: function (e) {
      if (window.scrollY > 500) {
        this.interval = setInterval(() => {
          if (this.value >= this.aboutSingleton.numberProducts) {
            return (this.value = this.aboutSingleton.numberProducts  );
          }
          if (this.aboutSingleton.numberProducts >= 100000) {
            this.value += 11000;
          } else if (this.aboutSingleton.numberProducts <= 50000) {
            this.value += 1100;
          }
          
          
        }, 800);
        this.intervalBarstools = setInterval(() => {
          if (this.valueBarstools >= this.aboutSingleton.numberBarstools) {
            return (this.valueBarstools = this.aboutSingleton.numberBarstools);
          }
          if (this.aboutSingleton.numberBarstools >= 100000) {
            this.valueBarstools += 101000;
          } else if (this.aboutSingleton.numberBarstools <= 50000) {
            this.valueBarstools += 1100;
          }
        }, 800);
      }
    },
  },
};
</script>
